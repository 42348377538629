import { useState, useEffect } from "react";
import {
  LIVE_SCREEN,
  BIO_SCREEN,
  MAIN_SCREEN,
  SONGS_SCREEN,
  VIDEOS_SCREEN,
  CONTACT_SCREEN,
} from "../Constants";
import styles from "./Header.module.css";

function Header(props) {
  const [bgColor, setBgColor] = useState("");
  const [actualSite, setActualSite] = useState(MAIN_SCREEN);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const updateDimensions = () => {
    if (window.innerWidth > 700) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions, { passive: true });
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  function getContent() {
    if (actualSite !== MAIN_SCREEN) {
      return <h3>Waltraud Köttler</h3>;
    }
  }

  function changeMenuVisibility() {
    setIsMenuOpen(!isMenuOpen);
  }

  const getMenuOpen = () => {
    if (isMenuOpen && isMobile) {
      return "block";
    } else if (!isMenuOpen && isMobile) {
      return "none";
    } else {
      return "flex";
    }
  };
  const getHeaderStyle = () => {
    if (isMenuOpen && isMobile) {
      return {
        display: getMenuOpen(),
        width: "100vw",
        position: "fixed",
        backgroundColor: "#FFFFFF",
        zIndex: 1000,
      };
    } else {
      return {
        backgroundColor: bgColor,
        display: getMenuOpen(),
        width: "100vw",
      };
    }
  };

  const getMenuOpenIconStyle = () => {
    if (isMobile && isMenuOpen) {
      return { display: "none" };
    } else if (isMobile && !isMenuOpen) {
      return { display: "flex", backgroundColor: "white" };
    } else {
      return { display: "none" };
    }
  };

  const getActiveSiteName = () => {
    switch (actualSite) {
      case VIDEOS_SCREEN:
        return "Videos";
      case SONGS_SCREEN:
        return "Songs";
      case BIO_SCREEN:
        return "About";
      case CONTACT_SCREEN:
        return "Contact";
      case LIVE_SCREEN:
        return "Live";

      default:
        return "";
    }
  };

  const getStyle = (el) => {
    return el === actualSite ? styles.active : styles.inactive;
  };

  return (
    <div>
      <div style={getMenuOpenIconStyle()} className={styles.mobileMenu}>
        <div className={styles.mobileMenuIcon} onClick={changeMenuVisibility}>
          <i className="fa-solid fa-bars"></i>
          <p>{getActiveSiteName()}</p>
        </div>
        <div>Waltraud Köttler</div>
      </div>

      <div className={styles.header} style={getHeaderStyle()}>
        <div className={styles.logocontainer}>
          <div
            className={styles.logo}
            onClick={() => {
              props.onChange(MAIN_SCREEN);
              setBgColor("");
              setActualSite(MAIN_SCREEN);
              setIsMenuOpen(false);
            }}
          >
            {getContent()}
          </div>
        </div>
        <ul className={styles.list}>
          {isMobile ? (
            <li
              onClick={() => {
                props.onChange(MAIN_SCREEN);
                setBgColor("");
                setActualSite(MAIN_SCREEN);
                setIsMenuOpen(false);
              }}
            >
              Home
            </li>
          ) : (
            <></>
          )}
          {/* <li
            className={getStyle(SONGS_SCREEN)}
            onClick={() => {
              props.onChange(SONGS_SCREEN);
              setActualSite(SONGS_SCREEN);
              setBgColor("#FFFFFF");
              setIsMenuOpen(false);
            }}
          >
            Songs
          </li> */}
          <li
            className={getStyle(VIDEOS_SCREEN)}
            onClick={() => {
              props.onChange(VIDEOS_SCREEN);
              setActualSite(VIDEOS_SCREEN);
              setBgColor("#FFFFFF");
              setIsMenuOpen(false);
            }}
          >
            Music
          </li>

          <li
            className={getStyle(LIVE_SCREEN)}
            onClick={() => {
              props.onChange(LIVE_SCREEN);
              setActualSite(LIVE_SCREEN);
              setBgColor("");
              setIsMenuOpen(false);
            }}
          >
            Live
          </li>
          <li
            className={getStyle(BIO_SCREEN)}
            onClick={() => {
              props.onChange(BIO_SCREEN);
              setActualSite(BIO_SCREEN);
              setBgColor("");
              setIsMenuOpen(false);
            }}
          >
            About
          </li>
          <li
            className={getStyle(CONTACT_SCREEN)}
            onClick={() => {
              props.onChange(CONTACT_SCREEN);
              setActualSite(CONTACT_SCREEN);
              setBgColor("");
              setIsMenuOpen(false);
            }}
          >
            Contact
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Header;
