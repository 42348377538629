import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";


function Video(props) {
  const url = "https://www.youtube.com/embed/" + props.id;
  return (
    <Card style={{backgroundColor:"#FFFFFFAA"}}>
      <CardMedia component="iframe" height="240"  src={url} alt={props.title} />
      <CardContent>
        <h3>{props.title}</h3>
        <p>
         {props.description}
        </p>
      </CardContent>
    </Card>
  );
}

export default Video;
