import { motion } from "framer-motion";
import styles from "./MainScreen.module.css";

function MainScreen() {
  const motions = {
    initial: { opacity: 0, x: 0 },
    animate: { opacity: 1, x: 0, transition: { duration: 0.2 } },
    exit: { opacity: 0, x: 0, transition: { duration: 0.2 } },
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={motions}
    >
      <div id="main" className={styles.full}>
        <div className={styles.content}>
          <h1>Waltraud Köttler</h1>
          <h2 className={styles.sub}>Songs for Real</h2>
          <div className="spotify">
            <a
              href="https://open.spotify.com/album/5DEAtiKSb6OVRphNxSeueZ?si=n9ipwnN5RS-U9p8o4CVgHA"
              rel="noreferrer"
              target="_blank"
            >
              <i className="fa fa-spotify fa-1x icon" aria-hidden="true"></i>
            </a>
            <a
              href="https://music.apple.com/de/artist/waltraud-k%C3%B6ttler/1618064499"
              rel="noreferrer"
              target="_blank"
            >
            <i className="fa fa-apple fa-1x icon" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default MainScreen;
