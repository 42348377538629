import Song from "./Song";
import styles from "./SongList.module.css";

import "./SongList.css";
import { Grid } from "@mui/material";

function SongList(props) {
  function getSongs() {
    let widget = [];
    let count = 0;
    props.songs.forEach((song) => {
      widget.push(
        <Grid item xs={4} ms={4} key={count}>
          <Song title={song.title} description={song.description} />
        </Grid>
      );
      count++;
    });
    return widget;
  }

  return (
    <div
      className={styles.songlist}
      style={{ padding: "50px", fontSize: "2rem" }}
    >
      <p className={styles.text}>
       {props.text}
      </p>
      <Grid
        container
        spacing={{ xs: 1, md: 1 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {getSongs()}
      </Grid>
    </div>
  );
}

export default SongList;
