export const MAIN_SCREEN = 1;
export const LIVE_SCREEN = 2;
export const SONGS_SCREEN = 3;
export const VIDEOS_SCREEN = 4;
export const BIO_SCREEN = 5;
export const CONTACT_SCREEN = 6;
export const getAnimation=  {
      initial: { opacity: 0, x: 0 },
      animate: { opacity: 1, x: 0, transition: { duration: 0.5 } },
      exit: { opacity: 0, x: 0, transition: { duration: 0.5 } },
    };


