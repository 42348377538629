import { Grid } from "@mui/material";
import Video from "../components/Video";
import styles from "./Videos.module.css";
function Videos(props) {
  function getVideos() {
    let widget = [];
    let counter = 0;
    props.videos.forEach((video) => {
      widget.push(
        <Grid item xs={6} ms={4} key={counter}>
          <Video
            height="300px"
            id={video.videoid}
            title={video.title}
            description={video.description}
          />
        </Grid>
      );
      counter++;
    });
    return widget;
  }
  return (
    <div id="videos" className={styles.videos}>
      <div className={styles.container}>
        <div className={styles.items}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 6, md: 12 }}
          >
            {getVideos()}
          </Grid>{" "}
        </div>
      </div>
    </div>
  );
}

export default Videos;
