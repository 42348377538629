import styles from "./Live.module.css";
import { motion } from "framer-motion";
import LiveCard from "../components/LiveCard";


const motions = {
  initial: { opacity: 0, x: 0 },
  animate: { opacity: 1, x: 0, transition: { duration: 0.9 } },
  exit: { opacity: 0, x: 0, transition: { duration: 0.9 } },
};

function Live(props) {
  const getEvents = ()=>{
    let widget = [];
    let key=1;
    //props.events.sort((a, b)=>  new Date(a.date)>new Date(b.date) );
    props.events.forEach(event => {
      widget.push(<LiveCard key={key} location={event.location} date={event.date} url={event.url} address={event.address} description={event.description}/>)
      key++;
    });
    return widget;
  }
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={motions}
    >
      <div  className={styles.full}>
        <div className={styles.content}>
         
         {getEvents()}
        </div>
        <div className={styles.overlay}></div>
      </div>
    </motion.div>
  );
}

export default Live;
