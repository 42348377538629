import styles from "./Contact.module.css";
import { motion } from "framer-motion";
import TextBlock from "../components/TextBlock";

const motions = {
  initial: { opacity: 0, x: 0 },
  animate: { opacity: 1, x: 0, transition: { duration: 0.2 } },
  exit: { opacity: 0, x: 0, transition: { duration: 0.2 } },
};

function Contact() {
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={motions}
    >
      <div className={styles.full}>
        <div className={styles.content}>
          <TextBlock text="waltraud.koettler@gmail.com" />
        </div>
        <div className={styles.partners}>
          <a
            href="https://www.adis-guitar.at/"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/partners/adi.png" width="120px" alt="Logo Adi" />
          </a>
          <a
            href="https://www.koenigstoechter.at/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/partners/kt.jpeg"
              width="120px"
              alt="logo königstöchter"
            />
          </a>
          <a
            href="https://birgitriedmann.at"
            target="_blank"
            rel="noreferrer"
            style={{position:"relative",top:"-13px",marginLeft:"10px"}}
          >
           birgitriedmann.at
          </a>
        </div>
        <div className={styles.impressum} >
          <a href="/impressum_wal.pdf" target="_blank" rel="noreferrer">
            Impressum
          </a>
        </div>
        <div className={styles.overlay}></div>
      </div>
    </motion.div>
  );
}

export default Contact;
