import styles from "./LiveCard.module.css";

function LiveCard(props) {
    const cardClick = ()=>{
        window.open(props.url, "_blank")
    }

    const getDateFormatted = ()=>{
        return new Date(props.date).toLocaleDateString("de", {  year:"numeric", month:"short", day:"numeric"}) ;
    }

    const getDayFormatted = ()=>{
        return new Date(props.date).toLocaleDateString("de", {  weekday:"long"}) ;
    }
    return (
    <div className={styles.card} onClick={cardClick}>
       <div className={styles.date}>
           <div>{getDateFormatted()}</div>
           <div>{getDayFormatted()}</div>
        </div>
        <div className={styles.location}>
            <div className={styles.heading}>{props.location}</div>
            <div>{props.address}</div>
            <div>{props.description}</div>
        </div>
    </div> );
}

export default LiveCard;