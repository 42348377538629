import MainScreen from "./screens/MainScreen";
import Bio from "./screens/Bio";
import Header from "./components/Header";
import Songs from "./screens/Songs";
import Videos from "./screens/Videos";
import { useEffect, useState } from "react";
import {
  LIVE_SCREEN,
  BIO_SCREEN,
  MAIN_SCREEN,
  SONGS_SCREEN,
  VIDEOS_SCREEN,
  CONTACT_SCREEN,
} from "./Constants";
import Live from "./screens/Live";
import Contact from "./screens/Contact";
import "./App.css";

function App() {
  const [actualSite, setActualSite] = useState(1);
  const [songs, setSongs] = useState([]);
  const [videos, setVideos] = useState([]);
  const [songsText, setSongsText] = useState("");
  const [liveEvents, setLiveEvents] = useState([]);

  useEffect(() => {
    fetch(
      "https://api.riedmann.rocks/waltraud/items/songs?status=published&fields=title, description,image.private_hash"
    )
      .then((response) => response.json())
      .then((json) => {
        setSongs(json.data);
      });
  }, []);

  useEffect(() => {
    fetch("https://api.riedmann.rocks/waltraud/items/videos?status=published&sort=-created_on")
      .then((response) => response.json())
      .then((json) => {
        setVideos(json.data);
      });
  }, []);
  useEffect(() => {
    fetch(
      "https://api.riedmann.rocks/waltraud/items/songstext?status=published"
    )
      .then((response) => response.json())
      .then((json) => {
        setSongsText(json.data[0].text);
      });
  }, []);

  useEffect(() => {
    fetch(
      "https://api.riedmann.rocks/waltraud/items/live?status=published&sort=-date"
    )
      .then((response) => response.json())
      .then((json) => {
        setLiveEvents(json.data);
      });
  }, []);
  //https://api.riedmann.rocks/waltraud/items/live?status=published

  function changeSite(siteId) {
    setActualSite(siteId);
  }

  function getContent() {
    switch (actualSite) {
      case MAIN_SCREEN:
        return <MainScreen />;
      case LIVE_SCREEN:
        return <Live events={liveEvents} />;
      case SONGS_SCREEN:
        return <Songs songs={songs} text={songsText} />;
      case VIDEOS_SCREEN:
        return <Videos videos={videos} />;
      case BIO_SCREEN:
        return <Bio />;
      case CONTACT_SCREEN:
        return <Contact />;
      default:
        return <MainScreen />;
    }
  }
  return (
    <div>
      <Header onChange={changeSite} />
      {getContent()}
    </div>
  );
}

export default App;
