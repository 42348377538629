
import styles from "./Song.module.css"

import { FaSpotify, FaSoundcloud, FaYoutube } from "react-icons/fa";
function Song(props) {
  //const assetURL = "https://api.riedmann.rocks/waltraud/assets/" + props.image;
 
  return (
    <div className={styles.card}>  
      <p className={styles.text}>{props.title}</p>
      <div className={styles.media}> <FaSpotify/><FaSoundcloud/><FaYoutube/></div>
     
    </div>
   
  );
}

export default Song;
