import styles from "./Songs.module.css";
import { motion } from "framer-motion";
import SongList from "../components/SongList";

const motions = {
  initial: { opacity: 0, x: 0 },
  animate: { opacity: 1, x: 0, transition: { duration: 0.9 } },
  exit: { opacity: 0, x: 0, transition: { duration: 0.9 } },
};

function Songs(props) {
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={motions}
    >
      <div id="songs" className={styles.full}>
        <div className={styles.content}>
          <SongList songs={props.songs} text={props.text}/>
        </div>
        <div className={styles.overlay}></div>
      </div>
    </motion.div>
  );
}

export default Songs;
