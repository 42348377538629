import styles from "./Bio.module.css";
import { motion } from "framer-motion";
import TextBlock from "../components/TextBlock";

const motions = {
  initial: { opacity: 0, x: 0 },
  animate: { opacity: 1, x: 0, transition: { duration: 0.2 } },
  exit: { opacity: 0, x: 0, transition: { duration: 0.2 } },
};

function Bio() {
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={motions}
    >
      <div className={styles.full}>
        <div className={styles.content}>
          <TextBlock
            text="Waltraud Köttler ist eine österreichische Singer- Songwriterin mit 35 Jahren
Bühnenerfahrung in verschiedensten Formationen.
In ihrem neuesten Herzensprojekt Songs for real widmet sie sich voll und ganz der
Reduktion auf Essenz, Echtheit und Leichtigkeit.
Prädestinierte Musiker*innen sind für intimes Eintauchen in diesen Musikraum
obligat, aber bei weitem nicht selbstverständlich.
Im Einklang mit Waltraud Köttler (songs &amp; lyrics, voice , piano)
stehen Andreas Riedmann (git), Didi Konzett (dr, perc) &amp; Lisa Ess (voice)"
          />
        </div>
        <div className={styles.overlay}></div>
      </div>
    </motion.div>
  );
}

export default Bio;
